import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import SocialIcon from "../social/socialIcon"
import FormattedLink from "../formattedLink/formattedLink"

import LTGLogo from "../../../assets/svg/logoLtg.svg"

import styles from "./footer.module.scss"

export const PureFooter = ({ data, hideAllMenus }) => {
  const { copyright, _rawCategory: nav, _rawLegal: legal, socialMediaLabel } = data.sanityFooterNavigation
  const { _rawPlatform: social } = data.sanitySocialMedia

  let copyrightYear = new Date()
  copyrightYear = copyrightYear.getFullYear()

  const Categories = () => (
    <>
      {nav.map(category => (
        <div key={category._key} className={styles.category}>
          <p className={styles.categoryTitle}>{category.category}</p>
          <ul key={category._key}>
            {category.links.map(link => (
              <li key={link._key} className={styles.categoryItem}>
                <FormattedLink link={link.url} label={link.label} newTab={link.newTab} />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  )

  const Legal = () => (
    <ul className={styles.legalNav}>
      {legal.map(link => (
        <li key={link._key} className={styles.legalItem}>
          <FormattedLink link={link.url} label={link.label} newTab={link.newTab} />
        </li>
      ))}
    </ul>
  )

  const SocialPlatforms = () => (
    <div className={styles.social}>
      <p className={styles.categoryTitle}>{socialMediaLabel}</p>
      <ul className={styles.socialList}>
        {social.map(link => (
          <li key={link._key}>
            <a href={link.url} target="_blank" rel="nofollow noopener noreferrer">
              <span>Link to {link.platform}</span>
              <SocialIcon type={link.platform} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )

  return (
    <footer className={styles.footer}>
      {!hideAllMenus && (
        <div className={styles.nav}>
          <div className={styles.navWrapper}>
            <Categories />
            <SocialPlatforms />
          </div>
        </div>
      )}
      <div className={styles.legal}>
        <div className={styles.legalWrapper}>
          <Legal />
          <p className={styles.copy}>
            <span>
              &copy; {copyrightYear} {copyright}
            </span>
            <span className={styles.ltgLogo}>
              Part of{" "}
              <a href="https://ltgplc.com/" target="_blank" rel="nofollow noopener noreferrer">
                Learning Technologies Group <LTGLogo />
              </a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  )
}

export const Footer = props => {
  const data = useStaticQuery(graphql`
    {
      sanityFooterNavigation {
        copyright
        _rawCategory
        _rawLegal
        socialMediaLabel
      }
      sanitySocialMedia {
        _rawPlatform
      }
    }
  `)

  return <PureFooter {...props} data={data}></PureFooter>
}

export default Footer
