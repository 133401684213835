import styles from "./nav.module.scss"

export const trackScroll = navRef => {
  let prevScroll = window.scrollY || document.documentElement.scrollTop
  let direction
  let curScroll
  let prevDirection

  const checkScroll = () => {
    curScroll = window.scrollY || document.documentElement.scrollTop
    direction = curScroll < prevScroll ? "up" : "down"

    if (direction !== prevDirection) {
      toggleNav(direction, curScroll)
    }

    prevScroll = curScroll
  }

  const toggleNav = (direction, curScroll) => {
    if (curScroll > navRef?.current?.scrollHeight && navRef.current) {
      if (direction === "up") {
        navRef.current.classList.add(styles.scrollUp)
        navRef.current.classList.remove(styles.scrollDown)
        prevDirection = direction
      } else if (direction === "down") {
        navRef.current.classList.remove(styles.scrollUp)
        navRef.current.classList.add(styles.scrollDown)
        prevDirection = direction
      }
    } else if (navRef.current) {
      navRef.current.classList.remove(styles.scrollUp)
      navRef.current.classList.remove(styles.scrollDown)
    }
  }

  return checkScroll
}
