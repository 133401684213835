/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */

import React, { useState, useEffect, useRef } from "react"
import cx from "classnames"
import { Link, useStaticQuery, graphql } from "gatsby"
import { isChrome } from "react-device-detect"

import Button from "../button/button"
import NavMobileButton from "./navMobileButton"
import Logo from "../../../assets/svg/logoGomo.svg"
import NavOurWork from "./navOurWork"
import NavWhatIsGomo from "./navWhatIsGomo"
import NavUtility from "./navUtility"
import FormattedLink from "../formattedLink/formattedLink"
import { trackScroll } from "./navHelpers"

import styles from "./nav.module.scss"
import useResize from "../../hooks/useResize"
import NavResources from "./navResources"

// TODO:
// skip to content
// accessibility audit

export const PureNav = props => {
  const { data, hideAllMenus } = props
  const [isOpen, setIsOpen] = useState(false)
  const [isChromeBrowser, setIsChromeBrowser] = useState(null)
  const navWrapperRef = useRef(null)
  const navRef = useRef(null)
  const windowSize = useResize()

  const toggleDropdown = e => {
    const parent = e.target.parentElement.parentElement

    const currentlyOpen = navWrapperRef?.current?.querySelector(`.${styles.showDropdown}`)

    if (currentlyOpen) {
      closeDropdown()
    }

    if (parent !== currentlyOpen && !parent.classList.contains(styles.showDropdown)) {
      parent.classList.add(styles.showDropdown)
      e.target.setAttribute("aria-expanded", "true")
      document.addEventListener("click", handleClickOutside)
    }
  }

  const handleClickOutside = e => {
    if (!navRef?.current?.contains(e.target)) {
      closeDropdown()

      document.removeEventListener("click", handleClickOutside)
    }
  }

  const closeDropdown = () => {
    const currentlyOpen = navWrapperRef?.current?.querySelector(`.${styles.showDropdown}`)
    currentlyOpen.classList.remove(styles.showDropdown)
    currentlyOpen.firstElementChild.setAttribute("aria-expanded", "false")

    document.removeEventListener("click", handleClickOutside)
  }

  const keyboardHandler = e => {
    if (e.keyCode === 13) {
      toggleDropdown(e)
    }
  }

  useEffect(() => {
    setIsChromeBrowser(isChrome)
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", trackScroll(navWrapperRef))
  }, [navWrapperRef])

  useEffect(() => {
    if (isOpen) {
      document.documentElement.classList.add("no-scroll")
    } else {
      document.documentElement.classList.remove("no-scroll")
    }
  }, [isOpen])

  useEffect(() => {
    if (windowSize === "desktop") {
      document.documentElement.classList.remove("no-scroll")
    }
  }, [windowSize, isOpen])

  return (
    <div ref={navWrapperRef} className={cx(styles.mainWrapper, isChromeBrowser ? styles.isChrome : "")}>
      {!hideAllMenus && <NavUtility data={data.sanityNav._rawUtility} />}
      <div className={styles.nav}>
        <div className={styles.navWrapper}>
          {/* LOGO */}
          <Link to="/" className={styles.logo} alt="Gomo Learning: Go to homepage">
            <Logo />
          </Link>
          {/* MAIN NAV */}
          {!hideAllMenus && <NavMobileButton handleClick={() => setIsOpen(!isOpen)} isOpen={isOpen} />}
          {!hideAllMenus && (
            <nav className={styles.mainNav} aria-label="Main Navigation" data-mobile-open={isOpen} ref={navRef}>
              <ul className={styles.mainNavList} role="menubar" aria-label="Main Navigation" id="menu-container">
                {/* FIRST ITEM */}
                <li className={cx(styles.mainNavItem, styles.hasDropdown)} role="none">
                  <NavWhatIsGomo
                    toggleDropdown={toggleDropdown}
                    keyboardHandler={keyboardHandler}
                    data={data.sanityNav._rawNavCategory1}
                    icon={data.sanityNav.navCategory1.icon}
                  />
                </li>
                {/* SECOND ITEM */}
                <li className={cx(styles.mainNavItem, styles.hasDropdown)} role="none">
                  <NavOurWork
                    toggleDropdown={toggleDropdown}
                    keyboardHandler={keyboardHandler}
                    data={data.sanityNav._rawNavCategory2}
                    customers={data.sanityNav.navCategory2.customerstories}
                  />
                </li>
                {/* THIRD ITEM */}
                <li className={cx(styles.mainNavItem, styles.hasDropdown)} role="none">
                  <NavResources
                    toggleDropdown={toggleDropdown}
                    keyboardHandler={keyboardHandler}
                    data={data.sanityNav._rawNavCategory3}
                  />
                </li>
                {/* DIRECT LINKS */}
                {data.sanityNav._rawDirectLinks.map(link => (
                  <li className={styles.mainNavItem} key={link._key}>
                    <FormattedLink type={styles.mainNavItemTitleWrapper} link={link.url} newTab={link.newTab}>
                      <span className={styles.mainNavItemTitle}>{link.label}</span>
                    </FormattedLink>
                  </li>
                ))}
                {/* BUTTONS */}
                <li className={styles.buttons}>
                  {data.sanityNav._rawLinks.map(link => (
                    <Button link={link.url} key={link._key} {...link}>
                      {link.label}
                    </Button>
                  ))}
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </div>
  )
}

export const Nav = props => {
  const data = useStaticQuery(graphql`
    {
      sanityNav {
        _rawNavCategory1
        _rawNavCategory2
        _rawNavCategory3
        _rawUtility
        _rawLinks
        _rawDirectLinks
        navCategory1 {
          icon {
            alt
            asset {
              url
            }
          }
        }
        navCategory2 {
          customerstories {
            tabs {
              content {
                logo {
                  asset {
                    fluid(maxWidth: 150) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }
  `)

  return <PureNav {...props} data={data} />
}

export default Nav

/* eslint-enable jsx-a11y/no-noninteractive-element-to-interactive-role */
