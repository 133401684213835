/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from "react"
import cx from "classnames"

import FormattedLink from "../formattedLink/formattedLink"

import styles from "./nav.module.scss"

const NavResources = ({ toggleDropdown, keyboardHandler, data }) => (
  <>
    <p className={styles.mainNavItemTitleWrapper} role="none">
      <span
        role="menuitem"
        aria-haspopup={true}
        onKeyDown={keyboardHandler}
        onClick={toggleDropdown}
        tabIndex="0"
        aria-expanded={false}
        className={cx(styles.mainNavItemTitle, styles.dropdown)}
      >
        {data?.category}
      </span>
    </p>
    <div className={cx(styles.dropdownContent, styles.narrow)}>
      <div className={cx(styles.dropdownContentLinksWrapper)}>
        <ul role="menu" className={styles.ourWorkLinks}>
          <li role="none" className={styles.ourWorkLinksTitle}>
            <FormattedLink
              type="link-pink"
              link={data.link1.url}
              role="menuitem"
              newTab={data.link1.newTab}
              activeClassName={styles.active}
            >
              {data.link1.label}
            </FormattedLink>
            <p className={styles.ourWorkLinksDescription}>{data.link1.description}</p>
          </li>
          <li role="none" className={styles.ourWorkLinksTitle}>
            <FormattedLink
              type="link-pink"
              link={data.link2.url}
              role="menuitem"
              newTab={data.link2.newTab}
              activeClassName={styles.active}
            >
              {data.link2.label}
            </FormattedLink>
            <p className={styles.ourWorkLinksDescription}>{data.link2.description}</p>
          </li>
          <li role="none" className={styles.ourWorkLinksTitle}>
            <FormattedLink
              type="link-pink"
              link={data.link3.url}
              role="menuitem"
              newTab={data.link3.newTab}
              activeClassName={styles.active}
            >
              {data.link3.label}
            </FormattedLink>
            <p className={styles.ourWorkLinksDescription}>{data.link3.description}</p>
          </li>
        </ul>
      </div>
    </div>
  </>
)

export default NavResources
/* eslint-enable jsx-a11y/no-noninteractive-element-to-interactive-role */
