import { useState, useEffect } from "react"
import * as _ from "underscore"

const useResize = () => {
  const tablet = 1024
  const isSSR = typeof window === "undefined"

  const getDevice = () => {
    const width = isSSR ? 1200 : window.innerWidth

    return width < tablet ? "mobile" : "desktop"
  }

  const [savedDevice, setSavedDevice] = useState(getDevice())

  useEffect(() => {
    const changeWindowSize = () => setSavedDevice(getDevice())

    window.addEventListener("resize", _.throttle(changeWindowSize, 500))

    return () => {
      window.removeEventListener("resize", changeWindowSize)
    }
  }, [])

  return savedDevice
}
export default useResize
