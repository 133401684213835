import React from "react"
import PropTypes from "prop-types"

import FormattedLink from "../formattedLink/formattedLink"

import "./button.scss"

const Button = props => <FormattedLink {...props} />

export default Button

Button.propTypes = {
  newTab: PropTypes.bool,
  noFollow: PropTypes.bool,
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}
