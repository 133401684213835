import React from "react"
import cx from "classnames"

import Reveal from "react-reveal/Reveal"

import styles from "./wrapper.module.scss"

// General Wrapper for components

const Wrapper = ({ children, background, noPadding = false, reveal = true }) => (
  <>
    {reveal ? (
      <Reveal effect="fadeInDown">
        <section className={cx(noPadding ? "" : styles.paddings, styles.fullWidth, styles[background || "white"])}>
          <div className={styles.wrapper}>{children}</div>
        </section>
      </Reveal>
    ) : (
      <section className={cx(noPadding ? "" : styles.paddings, styles.fullWidth, styles[background || "white"])}>
        <div className={styles.wrapper}>{children}</div>
      </section>
    )}
  </>
)

export default Wrapper
