import React from "react"

import FormattedLink from "../formattedLink/formattedLink"
import styles from "./navUtility.module.scss"

const NavUtility = ({ data }) => (
  <div className={styles.utility}>
    <div className={styles.utilityWrapper}>
      {data.map(link => (
        <FormattedLink key={link._key} type={styles.utilityItem} link={link.url} newTab={link.newTab}>
          {link.label}
        </FormattedLink>
      ))}
    </div>
  </div>
)

export default NavUtility
