import React from "react"

import Nav from "./nav/nav"

const Header = ({ hideAllMenus }) => (
  <header>
    <Nav hideAllMenus={hideAllMenus} />
  </header>
)

export default Header
