import React from "react"

import styles from "./navMenuButton.module.scss"

const NavMobileButton = props => (
  <button
    className={styles.menuButton}
    aria-expanded={props.isOpen}
    aria-controls="menu-container"
    onClick={props.handleClick}
  >
    <span className={styles.menuLabel}>Menu</span>
    <span className={styles.menuIcon}></span>
  </button>
)

export default NavMobileButton
