import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { LinkTest, LinkFormat } from "../../helpers/linkTest"

const FormattedLink = props => {
  const newTab = props.newTab ? { target: "_blank" } : null
  const noFollow = props.noFollow ? { rel: "nofollow noopener noreferrer" } : null
  const link = props.link
  const linkType = LinkTest(link)

  const Internal = () => (
    <Link
      className={props.type}
      to={LinkFormat(link)}
      {...newTab}
      {...noFollow}
      data-testid="formattedLink"
      role={props.role}
      activeClassName={props.activeClassName}
    >
      {props.label || props.children}
    </Link>
  )

  const External = () => (
    <a
      href={LinkFormat(link)}
      className={props.type}
      {...newTab}
      {...noFollow}
      data-testid="formattedLink"
      role={props.role}
    >
      {props.label || props.children}
    </a>
  )

  return <>{linkType === "internal" && !newTab ? <Internal /> : <External />}</>
}

export default FormattedLink

FormattedLink.propTypes = {
  newTab: PropTypes.bool,
  noFollow: PropTypes.bool,
  link: PropTypes.string.isRequired,
  label: PropTypes.string,
}
