import React, { Component } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import Header from "../header"
import Footer from "../footer/footer"

import "../../_global/base.scss"
import "../../_global/grid.scss"
import "../../_global/typography.scss"
import "../../_global/animations.scss"
import "./layout.scss"

class Layout extends Component {
  render(props) {
    return (
      <>
        <Helmet>
          <script type="text/javascript" src="/js/modernizr.js" defer></script>
        </Helmet>
        <Header hideAllMenus={this.props.hideAllMenus} />
        <main>{this.props.children}</main>
        <Footer hideAllMenus={this.props.hideAllMenus} />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
