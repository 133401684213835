/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from "react"
import cx from "classnames"
import Img from "gatsby-image"

import FormattedLink from "../formattedLink/formattedLink"

import styles from "./nav.module.scss"

const NavOurWork = ({ toggleDropdown, keyboardHandler, data, customers }) => (
  <>
    <p className={styles.mainNavItemTitleWrapper} role="none">
      <span
        role="menuitem"
        aria-haspopup={true}
        onKeyDown={keyboardHandler}
        onClick={toggleDropdown}
        tabIndex="0"
        aria-expanded={false}
        className={cx(styles.mainNavItemTitle, styles.dropdown)}
      >
        {data.category}
      </span>
    </p>
    <div className={cx(styles.dropdownContent, styles.dropdownContent2Col)}>
      {/* first column */}
      <div className={cx(styles.dropdownContentLinksWrapper)}>
        <ul role="menu" className={styles.ourWorkLinks}>
          <li role="none" className={styles.ourWorkLinksTitle}>
            <FormattedLink
              type="link-pink"
              link={data.link1.url}
              role="menuitem"
              newTab={data.link1.newTab}
              activeClassName={styles.active}
            >
              {data.link1.label}
            </FormattedLink>
            <p className={styles.ourWorkLinksDescription}>{data.link1.description}</p>
          </li>
          <li role="none" className={styles.ourWorkLinksTitle}>
            <FormattedLink
              type="link-pink"
              link={data.link2.url}
              role="menuitem"
              newTab={data.link2.newTab}
              activeClassName={styles.active}
            >
              {data.link2.label}
            </FormattedLink>
            <p className={styles.ourWorkLinksDescription}>{data.link2.description}</p>
          </li>
        </ul>
      </div>
      {/* second column */}
      <div className={styles.customerReviewsWrapper}>
        <FormattedLink type="link-pink" link="/customer-stories/" role="menuitem">
          Featured Customer Stories
        </FormattedLink>
        <div className={styles.customerReviewsLogos}>
          {customers.map((customer, i) => {
            const { logo, slug } = customer?.tabs?.content

            return (
              <div className={styles.customerReviewsLogo} key={slug.current}>
                <FormattedLink
                  key={`${i}-${logo.alt}`}
                  link={`/customer-stories/${slug.current}`}
                  role="menuitem"
                  activeClassName={styles.active}
                >
                  <Img
                    fluid={logo.asset.fluid}
                    alt={logo.alt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                </FormattedLink>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </>
)

export default NavOurWork
/* eslint-enable jsx-a11y/no-noninteractive-element-to-interactive-role */
