/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from "react"
import cx from "classnames"

import FormattedLink from "../formattedLink/formattedLink"

import styles from "./nav.module.scss"

const NavWhatIsGomo = ({ toggleDropdown, keyboardHandler, data, icon }) => {
  const toggleSmallDropdown = e => {
    const parent = e.target.parentElement

    if (parent.classList.contains(styles.showDropdown)) {
      parent.classList.remove(styles.showDropdown)
      e.target.setAttribute("aria-expanded", "false")
    } else {
      parent.classList.add(styles.showDropdown)
      e.target.setAttribute("aria-expanded", "true")
    }
  }
  return (
    <>
      <p className={styles.mainNavItemTitleWrapper} role="none">
        <span
          role="menuitem"
          aria-haspopup={true}
          aria-expanded={false}
          className={cx(styles.mainNavItemTitle, styles.dropdown)}
          onKeyDown={keyboardHandler}
          onClick={toggleDropdown}
          tabIndex="0"
        >
          {data.category}
        </span>
      </p>
      <div className={styles.dropdownContent}>
        <div className={styles.dropdownContentDescription}>
          <img alt={icon.alt} className={styles.dropdownContentDescriptionIcon} src={icon.asset.url} />
          <div className={styles.dropdownContentDescriptionText}>
            <p>{data.description}</p>
            <FormattedLink type="link-pink" link={data.link.url} role="menuitem" newTab={data.link.newTab}>
              {data.link.label}
            </FormattedLink>
          </div>
        </div>
        <div className={cx(styles.dropdownContentLinksWrapper, "grid")}>
          {data.links.map((category, i) => (
            <div key={category.category} className={cx(styles.categoryWrapper, "grid-item small-12 large-3")}>
              <p
                className={styles.categoryTitle}
                onKeyDown={keyboardHandler}
                onClick={toggleSmallDropdown}
                tabIndex="0"
              >
                {category.category}
              </p>
              <ul role="menu" className={styles.categoryList}>
                {category.links.map(link => (
                  <li key={link.label} role="none">
                    <FormattedLink
                      type={styles.link}
                      link={link.url}
                      role="menuitem"
                      newTab={link.newTab}
                      activeClassName={styles.active}
                    >
                      {link.label}
                    </FormattedLink>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default NavWhatIsGomo
/* eslint-enable jsx-a11y/no-noninteractive-element-to-interactive-role */
